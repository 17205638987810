<template>
  <div class="problem-box flex h-full">
    <div class="left w-1/2 p-4">
      <div class="sample-box">
        <span>输入用例</span>
        <el-input
          type="textarea"
          v-model="inputSample"
          placeholder="请输入内容"
          resize="none"
        ></el-input>
      </div>
      <div class="sample-box">
        <span>输出用例</span>
        <el-input
          type="textarea"
          v-model="outputSample"
          placeholder="请输入内容"
          resize="none"
        ></el-input>
      </div>
      <div class="sample-box">
        <span class="result">运行结果</span>
        <el-input
          type="textarea"
          placeholder="请运行代码"
          rows="10"
          resize="none"
          v-model="resultOutput"
        ></el-input>
      </div>
    </div>
    <div class="right w-1/2">
      <div class="tool-box px-2">
        <div class="flex items-center">
          <el-select
            v-model="language"
            size="mini"
            @change="handleChangeProgramLang"
          >
            <el-option
              v-for="item in languageList"
              :key="item.id"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
          <el-tooltip content="运行代码" placement="bottom" effect="light">
            <a class="tool-btn" @click="runCode">
              <svg-icon
                class="ml-2 icon2x cursor-pointer"
                iconClass="play"
              ></svg-icon>
            </a>
          </el-tooltip>
          <el-tag
            v-if="runCodeStatus === 0"
            class="ml-2"
            size="small"
            effect="dark"
            >待运行</el-tag
          >
          <el-tag
            v-else-if="runCodeStatus === 2"
            class="ml-2"
            type="success"
            size="small"
            effect="dark"
            >运行完成</el-tag
          >
          <el-tag v-else class="ml-2" type="warning" size="small" effect="dark"
            >运行中</el-tag
          >
        </div>
      </div>
      <ace-editor
        ref="ace"
        :config="aceEditorConfig"
        :content="code"
        @updateCodeValue="updateCodeVal"
        style="height: calc(100% - 40px); overflow: auto"
      ></ace-editor>
    </div>
  </div>
</template>

<script>
import AceEditor from './AceEditor.vue';
import { runContestCode, checkCodeResult } from '@/api/course';

export default {
  name: 'TestJudge',
  components: {
    AceEditor,
  },
  data() {
    return {
      aceEditorConfig: {
        maxLines: 200,
        minLines: 15,
      },
      language: 'C++',
      languageList: [
        {
          id: 0,
          label: 'C++',
        },
        {
          id: 1,
          label: 'Python3',
        },
      ],
      outputSample: '',
      inputSample: '无',
      code: '',
      resultOutput: '',
      resultStatus: 0,
      runCodeStatus: 0,
    };
  },
  methods: {
    updateCodeVal(val) {
      this.code = val;
    },
    runCode() {
      if (this.runCodeStatus !== 1) {
        this.runCodeStatus = 1;
        this.resultOutput = '';
        let timer = null;
        runContestCode({
          contest_id: 611,
          code: this.code,
          input_sample: this.inputSample,
          output_sample: this.outputSample,
          lang: this.language,
        })
          .then(() => {
            let i = 0;
            timer = setInterval(() => {
              checkCodeResult({
                contest_id: 611,
              }).then((res) => {
                i++;
                if (res.data.status === 2) {
                  clearInterval(timer);
                  this.resultOutput = res.data.result_output
                    ? res.data.result_output
                    : res.data.result_err_msg;
                  this.resultStatus = res.data.result_status;
                  this.tabActive = 'result';
                  this.runCodeStatus = 2;
                } else if (i === 10) {
                  clearInterval(timer);
                  this.$message({
                    message: '运行超时，请稍后重试',
                    type: 'error',
                  });
                  this.runCodeStatus = 0;
                }
              });
            }, 1500);
          })
          .catch(() => {
            clearInterval(timer);
            this.runCodeStatus = 0;
          });
      } else {
        this.$message({
          message: '代码正在运行中，请稍后重试',
          type: 'warning',
        });
      }
    },
    handleChangeProgramLang() {
      const lang = this.language;
      this.$refs.ace.setLanguageMode(lang);
    },
  },
};
</script>

<style lang="scss">
.problem-box {
  .el-result__subtitle {
    p {
      white-space: pre-wrap;
    }
  }
}
</style>

<style lang="scss" scoped>

.problem-box {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 20%);

  .left {
    .sample-box {
      margin-bottom: 20px;
      text-align: left;

      span {
        margin-bottom: 10px !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
      }

      .result {
        font-size: 22px;
      }
    }
  }

  .right {
    background-color: #19161d;
  }

  .tool-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: #f8f8f8;
  }

  .tool-btn {
    color: #5c5c5c;
  }

  .tool-btn:active {
    color: #409eff;
  }

  .icon2x {
    width: 1.5em !important;
    height: 1.5em !important;
  }
}
</style>
