<template>
  <div class="bg-gray-800">
    <div class="navbar-wrapper mx-auto" :class="[isContainer?'container':'']">
      <el-menu :default-active="$store.getters.activeIndex" class="md:flex items-center hidden bg-gray-800" mode="horizontal" @select="handleSelect">
        <img style="margin-left: 10px;height: 48px;" :src="logo" alt="logo">
        <el-menu-item index="/"><b>学习中心</b></el-menu-item>
        <el-menu-item index="/backage" v-permission="['teacher']"><b>教师后台</b></el-menu-item>
        <div class="test-judge-item" @click="handleOpenTestJudge">自测工具</div>
        <el-submenu index="0" v-if="name && avatar" class="mr-2" :style="{'max-width': '100px', 'margin-left':'auto'}">
          <template slot="title">
            <el-avatar :src="avatar" icon="el-icon-user-solid"></el-avatar>
          </template>
          <el-menu-item><a href="https://haimakid.net/user/profile"><i class="el-icon-setting"></i>我的信息</a></el-menu-item>
          <el-menu-item index="/logout"><i class="el-icon-back"></i>注销</el-menu-item>
        </el-submenu>
        <el-menu-item v-else index="/login" :style="{'max-width': '100px', 'margin-left':'auto'}">登录</el-menu-item>
      </el-menu>
      
      <div class="flex justify-between items-center md:hidden py-2 px-3 bg-white" style="border-bottom: 1px solid #e6e6e6;">
        <i class="el-icon-s-unfold text-2xl text-gray-500" @click="openDrawer = true"></i>
        <span>海码考试中心</span>
        <el-avatar v-if="name && avatar" :src="avatar" icon="el-icon-user-solid"></el-avatar>
        <span v-else @click="$router.push('/login')">登录</span>
      </div>
      <el-drawer
        title="我是标题"
        :visible.sync="openDrawer"
        direction="ltr"
        size="50%"
        :with-header="false">
        <el-menu :default-active="$store.getters.activeIndex" @select="handleSelect" class="mt-4">
          <el-menu-item>
            <img class="ml-2" src="https://scratch.haimakid.net/static/logo.png" alt="logo">
          </el-menu-item>
          <el-menu-item index="/">学习中心</el-menu-item>
          <el-menu-item v-if="name && avatar" class="mr-2" index="/logout">注销</el-menu-item>
        </el-menu>
      </el-drawer>
    </div>
    <el-dialog 
      title="自测工具"
      :visible.sync="testJudgeDialog"
      width="80%"
      center
    >
      <test-judge></test-judge>
    </el-dialog>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import logoImg from '@/assets/logo.png'

import TestJudge from '@/views/chapter/components/TestJudge.vue'

export default {
  name: 'Navbar',
  props: {
    isContainer: {
      type: Boolean,
      default: true
    }
  },
  components: {
    TestJudge
  },
  data() {
    return {
      openDrawer: false,
      drawerSize: '50%',
      logo: logoImg,
      testJudgeDialog: false,
    }
  },
  computed: {
    ...mapGetters([
      'avatar',
      'name'
    ])
  },
  methods: {
    handleSelect(key) {
      if (key === '/logo') {
        window.location.href = 'https://haimakid.net'
        return false
      }
      if (key === '/logout') {
        this.$store.dispatch('user/logout').then(() => { 
          this.$message({
            type: 'success',
            message: '注销成功',
            duration: 2000
          })
          this.$router.push('/login')
        })
      } else {
        this.$router.push(key)
      }

    },
    handleOpenTestJudge() {
      this.testJudgeDialog = true
    }
  }
}
</script>

<style>
.el-menu--collapse .el-menu .el-submenu, .el-menu--popup {
  min-width: 100px;
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
.el-menu--horizontal .el-menu, 
.el-menu--horizontal .el-menu .el-menu-item, 
.el-menu--horizontal>.el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  background-color: #1f2a37 !important;
  color: #fff;
}
.el-menu--horizontal>.el-menu-item.is-active {
  color: #409EFF;
}
.el-submenu__title:hover, .el-menu-item:focus, .el-menu-item:hover {
  background-color: #1f2a37 !important;
  color: #409EFF !important;
  border-bottom: 2px solid #409EFF !important;
}
.test-judge-item {
  padding: 0 15px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
</style>