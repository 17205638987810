import router from './router'
import store from './store'

import { Message } from 'element-ui'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import getPageTitle from '@/utils/page-title'

import { getRefreshToken, getToken } from './utils/auth'

NProgress.configure({ showSpinner: false })

const whiteList = ['/', '/login', '/404']

router.beforeEach(async(to, from, next) => {

  NProgress.start()

  document.title = getPageTitle(to.meta.title)

  // 设置导航选中
  store.dispatch('nav/setActiveIndex', to.path)

  const hasToken = getToken()

  if (hasToken) {
    // console.log(to.path)
    if (to.path === '/login') {
      // 如果已登录重定向至首页
      next('/')
      NProgress.done()
    } else {
      // 通过已有store中是否有昵称和头像信息去判断是否获取个人信息
      const hasInfo = store.getters.name && store.getters.avatar
      if (hasInfo) {
        const role = store.getters.eduRole
        if (!role && to.path != '/roles/index') {
          next(`/roles?to=${to.path}`)
        } else {
          next()
        }
        
      } else {
        try {
          // 获取用户信息
          await store.dispatch('user/getInfo')
          const role = store.getters.eduRole
          if (!role) {
            next(`/roles?to=${to.path}`)
          }
          // replace 设置为 true 导航不会留下历史
          next({ replace: true })
        } catch (error) {
          // 出错则退出登录
          await store.dispatch('user/logout')
          Message.error(error || 'Error')
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    // 检查是否有RefreshToken
    const hasRefreshToken = getRefreshToken()
    if (hasRefreshToken) {
      try {
        await store.dispatch('user/localRefreshToken', { hasRefreshToken })
              .then(() => {
                store.dispatch('user/getInfo') // 刷新用户信息
              })
        next()
      } catch (error) {
        console.log(error)
        Message.error('请登录')
        next(`/login?redirect=${to.path}`)
        NProgress.done()
      }
    } else {
      if (whiteList.indexOf(to.path) !== -1) {
        next()
      } else {
        Message.error('请登录')
        next(`/login?redirect=${to.path}`)
        NProgress.done()
      }
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})