<template>
  <div id="ace-editor">
    <div class="ace-container" ref="ace"></div>
  </div>
</template>

<script>
import Vue from 'vue'
import ace from 'ace-builds';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-c_cpp';
import 'ace-builds/src-noconflict/theme-tomorrow_night_bright';
import 'ace-builds/src-noconflict/snippets/c_cpp';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/snippets/python';

Vue.use(ace);

const DEFAULT_CONFIG = {
  MAX_LINES: 15,
  MIN_LINES: 15,
  FONT_SIZE: 18,
  THEME: 'ace/theme/tomorrow_night_bright',
  MODE: 'ace/mode/c_cpp'
};
export default {
  name: 'AceEditor',
  data() {
      return {
          aceEditor: null
      };
  },
  props: {
      content: {
          type: String,
          default: ''
      },
      config: {
          type: Object,
          default() {
              return {};
          }
      }
  },
  computed: {
    code: {
      set(val) {
        console.log(val);
      },
      get(val) {
        console.log(val);
        return val;
      }
    }
  },
  mounted() {
    this.aceEditor = ace.edit(this.$refs.ace, {
        value: this.content || '',
        maxLines: this.config.maxLines || DEFAULT_CONFIG.MAX_LINES, // 最大行数，超过会自动出现滚动条
        minLines: this.config.minLines || DEFAULT_CONFIG.MIN_LINES, // 最小行数，还未到最大行数时，编辑器会自动伸缩大小
        fontSize: this.config.fontSize || DEFAULT_CONFIG.FONT_SIZE, // 编辑器内字体大小
        theme: this.config.theme || DEFAULT_CONFIG.THEME, // 默认设置的主题
        mode: this.config.mode || DEFAULT_CONFIG.MODE, // 默认设置的语言模式
        showPrintMargin: false, // 禁止显示打印线
        wrap: false, // 自动换行
        tabSize: 4, // tab宽度
        enableSnippets: true, // 自动提示
        enableLiveAutocompletion: true, // 自动提示
        enableBasicAutocompletion: true // 自动提示
    })
    this.aceEditor.getSession().on('change', () => {
      this.$emit('updateCodeValue', this.getContent())
    })
  },
  methods: {
    /**
     * 设置编辑器内容
     * @param content
     */
    setContent(content) {
        this.aceEditor.setValue(content);
        // 解决设置内容后编辑器内容被选中的问题
        this.aceEditor.clearSelection();
    },

    /**
     * 获取编辑器内容
     */
    getContent() {
        return this.aceEditor.getSession().getValue();
    },

    /**
     * 锁定编辑器
     * @param flag
     */
    lockEditor: function (flag) {
        this.aceEditor.setReadOnly(flag);
    },

    /**
     * 设置编辑器行数
     * @param lineCount
     */
    setLineCount(lineCount) {
        this.aceEditor.setOptions({
            minLines: lineCount,
            maxLines: lineCount,
        });
    },

    /**
     * 根据父盒子重置编辑器宽度
     */
    resizeEditor() {
        this.aceEditor.resize();
    },

    /**
     * 获取编辑器字体大小
     */
    getFontSize() {
        return this.aceEditor.getFontSize();
    },

    setLanguageMode(lang) {
      switch (lang) {
        case 'Python3':
          this.aceEditor.setOptions({
            mode: 'ace/mode/python'
          })
          break;
        default:
          this.aceEditor.setOptions({
            mode: 'ace/mode/c_cpp'
          })
          break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  #ace-editor {
    .ace-container {
      .ace_gutter {
          background: #19171d;
      }
    }
  }
  .ace-container {
    background-color: #19171d;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      background-color: rgba(0, 0, 0, 0);
    }

    ::-webkit-scrollbar-thumb {
      background: rgba(140, 140, 140, .3);
      border-radius: 5px;
    }
  }
</style>