import request from '@/utils/request'
const baseURL = '/xinao.course'

/**
 * 获取课程列表
 * @param {*} params 
 * @returns 
 */
 export function list(params) {
  return request({
    url: `${baseURL}/list`,
    method: 'get',
    params
  })
}
/**
 * 获取课程详情
 * @param {*} params 
 * @returns 
 */
 export function detail(params) {
  return request({
    url: `${baseURL}/detail`,
    method: 'get',
    params
  })
}
/**
 * 获取章节详情
 * @param {*} params 
 * @returns 
 */
 export function chapter(params) {
  return request({
    url: `${baseURL}/chapter`,
    method: 'get',
    params
  })
}
/**
 * 获取资源详情
 * @param {*} params 
 * @returns 
 */
 export function section(params) {
  return request({
    url: `${baseURL}/section`,
    method: 'get',
    params
  })
}
/**
 * 获取测试详情
 * @param {*} params 
 * @returns 
 */
 export function contest(params) {
  return request({
    url: `${baseURL}/contest`,
    method: 'get',
    params
  })
}
/**
 * 更新学习状态
 * @param {*} data 
 * @returns 
 */
 export function updateStudyStatus(data) {
  return request({
    url: `${baseURL}/finishResource`,
    method: 'post',
    data
  })
}
/**
 * 更新作业进度
 * @param {*} data 
 * @returns 
 */
 export function updateHomeworkProgress(data) {
  return request({
    url: `${baseURL}/updateHomeworkProgress`,
    method: 'post',
    data
  })
}
/**
 * 保存代码
 * @param {*} data 
 * @returns 
 */
 export function saveContestCode(data) {
  return request({
    url: `${baseURL}/saveCode`,
    method: 'post',
    data
  })
}
/**
 * 运行代码
 * @param {*} data 
 * @returns 
 */
 export function runContestCode(data) {
  return request({
    url: `${baseURL}/runCode`,
    method: 'post',
    data
  })
}
/**
 * 检查代码结果
 * @param {*} params 
 * @returns 
 */
 export function checkCodeResult(params) {
  return request({
    url: `${baseURL}/checkCodeResult`,
    method: 'get',
    params
  })
}
/**
 * 获取代码结果列表
 * @param {*} params 
 * @returns 
 */
 export function codeResultList(params) {
  return request({
    url: `${baseURL}/codeResultList`,
    method: 'get',
    params
  })
}
/**
 * 获取章节学习状态
 * @param {*} params 
 * @returns
 */
 export function getSectionStudyStatus(params) {
  return request({
    url: `${baseURL}/getSectionStudyStatus`,
    method: 'get',
    params
  })
}
/**
 * 获取保存代码
 * @param {*} params 
 * @returns
 */
 export function getSaveCode(params) {
  return request({
    url: `${baseURL}/getSaveCode`,
    method: 'get',
    params
  })
}

/**
 * 获取班级排名
 * @param {*} params 
 * @returns
 */
 export function getClassmateRanking(params) {
  return request({
    url: `${baseURL}/getClassmateRanking`,
    method: 'get',
    params
  })
}
