import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        component: () => import('@/views/pages/home.vue'),
        name: 'Home',
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/404',
    component: () => import('@/views/pages/404.vue'),
    meta: {
      title: '404 Not Found'
    }
  },
  {
    path: '/course',
    component: Layout,
    redirect: '/course/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/course/index'),
        name: 'CourseIndex',
        meta: { title: '课程内容' }
      }
    ],
  },
  {
    path: '/chapter/:id/:sid',
    component: () => import('@/views/chapter/index'),
    name: 'ChapterIndex',
    meta: { title: '章节内容' }
  },
  {
    path: '/ucenter',
    component: Layout,
    redirect: '/ucenter/info',
    children: [
      {
        path: 'info',
        component: () => import('@/views/ucenter/info.vue'),
        name: 'UcenterInfo',
        meta: { title: '个人信息' }
      }
    ]
  },
  {
    path: '/backage',
    component: Layout,
    redirect: '/backage/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/backage/index.vue'),
        name: 'Backage',
        meta: { title: '后台管理' }
      }
    ],
  },
  {
    path: '/backage/study-status/',
    component: Layout,
    children: [
      {
        path: 'list/:sid/:cid',
        component: () => import('@/views/backage/study-status/list.vue'),
        name: 'StudyStatusList',
        meta: { title: '学生学习状态' },
      }
    ]
  },
  {
    path: '/roles',
    component: Layout,
    redirect: '/roles/index',
    children: [
      {
        path: 'index',
        name: 'RolesIndex',
        component: () => import('@/views/pages/roles'),
        meta: { title: '角色选择' }
      }
    ],
    
  },
  { path: '*', redirect: '/404' }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
